<template>
  <!--begin::Task Internal Listing-->
  <v-container fluid class="pa-0">
    <v-container>
      <v-row>
        <v-col md="2">
          <v-select
            :disabled="dataLoading"
            :loading="dataLoading"
            :items="statusList"
            v-model="filter.status"
            hide-details
            flat
            solo
            dense
            label="Status"
            color="cyan"
            item-color="cyan"
            class="pt-0 mt-0"
            item-text="text"
            item-value="value"
            v-on:change="getAllTasks"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.description"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip :color="item.color" :text-color="item.textcolor">
                  <template v-if="item.value == 'all'">{{
                    item.all_tasks
                  }}</template>
                  <template v-else>{{ item.task_status_count }}</template>
                </v-chip>
              </v-list-item-action>
            </template>
          </v-select>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="getAllTasks"
            @keydown.tab="getAllTasks"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                flat
                label="Dates Range"
                hide-details
                solo
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click:clear="clearFilter('dates')"
                clearable
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="formattedDate"
                color="cyan"
              ></v-text-field>
            </template>
            <v-date-picker
              color="cyan"
              range
              :disabled="dataLoading"
              :loading="dataLoading"
              v-on:change="getAllTasks"
              v-model="dates"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2" class="d-flex">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getAllTasks"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <template v-if="detail.status < 5">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-on:click="presetDialog = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create New Task</span>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="5"
      :dataLoading.sync="dataLoading"
      :rowData.sync="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Task #</th>
            <th class="simple-table-th" width="200">Subject</th>
            <th class="simple-table-th" width="200">Dates</th>
            <th class="simple-table-th" width="200">Associated Team</th>
            <th class="simple-table-th" width="200">Status</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length > 0">
            <tr
              link
              :key="index"
              v-for="(data, index) in dataRows"
              v-on:click="
                $router.push(
                  getDefaultRoute('task.detail', {
                    params: { id: data.id },
                  })
                )
              "
            >
              <td class="simple-table-td">
                <Barcode
                  route="task.detail"
                  :barcode="data.barcode"
                  :id="data.id"
                ></Barcode>
              </td>
              <td class="simple-table-td">
                <div class="task-listing">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Subject: </b>{{ data.subject }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Reference: </b>{{ data.reference }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="task-listing">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Start Date: </b>
                    <template v-if="data.start_date">{{
                      formatDate(data.start_date)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Start Date</em></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Due Date: </b>
                    <template v-if="data.due_date">{{
                      formatDate(data.due_date)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Due Date</em></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Finish Date: </b>
                    <template v-if="data.finish_date">{{
                      formatDate(data.finish_date)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >Not Finished Yet</em
                      ></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div v-if="lodash.isEmpty(data.members) === false">
                  <v-chip
                    v-for="(row, index) in data.members"
                    :key="index"
                    class="mr-2 mb-2"
                    small
                    label
                    color="cyan white--text"
                    outlined
                    >{{ row.user.display_name }}</v-chip
                  >
                </div>
                <template v-else>
                  <em class="text--secondary">No Team Associated</em>
                </template>
              </td>
              <td class="simple-table-td" width="100">
                <CustomStatus
                  endpoint="tasks/status"
                  small
                  :status.sync="data.status"
                ></CustomStatus>
                <Priority :priority.sync="data.priority" small></Priority>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="5">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no task at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <Dialog :common-dialog="presetDialog">
      <template v-slot:title> Task Presets </template>
      <template v-slot:body>
        <v-row>
          <v-col md="10" class="py-0" offset-md="1">
            <v-autocomplete
              dense
              color="cyan"
              filled
              label="Task Presets"
              solo
              flat
              item-color="cyan"
              item-text="title"
              item-value="id"
              hide-details
              :disabled="presetLoading"
              :loading="presetLoading"
              :items="preset_list"
              v-model="tpreset"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>No Task Preset Found.</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="presetLoading"
          :loading="presetLoading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="save_preset()"
        >
          Save
        </v-btn>
        <v-btn
          :disabled="presetLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="presetDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-container>
  <!--end::Task Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import { QUERY, GET, POST } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import CustomStatus from "@/view/pages/partials/CustomStatus";
import Priority from "@/view/pages/partials/Priority";
import Dialog from "@/view/pages/partials/Dialog";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "task-internal-list",
  data() {
    return {
      parent: 0,
      dates: [],
      statusList: [],
      dataRows: [],
      dataLoading: true,
      customerList: [],
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
      preset_list: [],
      tpreset: 0,
      presetDialog: false,
      presetLoading: false,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
    customerId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          this.getAllTasks();
        }
      },
    },
  },
  components: {
    Barcode,
    Dialog,
    CustomStatus,
    Priority,
    ListingTable,
  },
  methods: {
    save_preset() {
      let projectId = this.detail.id;
      let presetId = this.tpreset;
      this.presetLoading = true;
      this.$store
        .dispatch(POST, { url: `project/${projectId}/task-preset/${presetId}` })
        .then(() => {
          this.getAllTasks();
          this.presetDialog = false;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.presetLoading = false;
        });
    },
    get_preset_list() {
      this.presetLoading = true;
      this.$store
        .dispatch(GET, { url: "task/preset" })
        .then(({ data }) => {
          this.preset_list = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.presetLoading = false;
        });
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllTasks();
    },
    getAllTasks() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        status: _this.filter.status || "all",
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "tasks",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    this.get_preset_list();
  },
};
</script>
